/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorUsersAggregateWidget = {
    title: 'Users',
    hint: {
        avg: 'Average number of Users per Author content',
        max: 'Maximum number of Users per Author content',
        min: 'Minimum number of Users per Author content',
        default: 'Total number of Users for all authors',
        median: 'Median number of Users per Author content'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorUsersPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Users / Article',
    hint: {
        default:
            'Average Users / Article for all Authors in the selected period',
        max: 'Maximum Users / Article for all Authors in the selected period',
        min: 'Minimum Users / Article for all Authors in the selected period',
        median: 'Median Users / Article for all Authors in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorSessionsAggregateWidget = {
    title: 'Sessions',
    hint: {
        avg: 'Average number of Sessions per Author',
        max: 'Maximum number of Sessions per Author',
        min: 'Minimum number of Sessions per Author',
        default: 'Total number of Sessions for all Authors',
        median: 'Median number of Sessions per Author'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorOrganicSessionsAggregateWidget = {
    title: 'Organic Sessions',
    hint: {
        avg: 'Average number of Organic Sessions per Author',
        max: 'Maximum number of Organic Sessions per Author',
        min: 'Minimum number of Organic Sessions per Author',
        default: 'Total number of Organic Sessions for all Authors',
        median: 'Median number of Organic Sessions per Author'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorOrganicSessionsPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Organic Sessions / Article',
    hint: {
        avg: 'Average per Author number of Organic Sessions per Article',
        max: 'Maximum per Author number of Organic Sessions per Article',
        min: 'Minimum per Author number of Organic Sessions per Article',
        default: 'Total per Author number of Organic Sessions per Article',
        median: 'Median per Author number of Organic Sessions per Article'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorSessionsPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Sessions / Article',
    hint: {
        avg: 'Average per Author number of Sessions per Article',
        max: 'Maximum per Author number of Sessions per Article',
        min: 'Minimum per Author number of Sessions per Article',
        default: 'Total per Author number of Sessions per Article',
        median: 'Median per Author number of Sessions per Article'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorPageViewsAggregateWidget = {
    title: 'Page Views',
    hint: {
        avg: 'Average number of Page Views per Author',
        max: 'Maximum number of Page Views per Author',
        min: 'Minimum number of Page Views per Author',
        default: 'Total number of Page Views for all Authors',
        median: 'Median number of Page Views per Author'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorArticlesCountAggregateWidget = {
    defaultMetric: 'default',
    title: 'Articles',
    hint: {
        avg: 'Average number of articles written by one author in the selected period. If article is published in more than one Publication, it is counted for each Publication',
        max: 'Number of articles written by the most productive author in the selected period.',
        min: 'Number of articles written by the least productive author in the selected period.',
        default:
            'Total number of articles written by all authors in the selected period. If article is published in more than one Publication, it is counted for each Publication',
        median: 'Median number of articles written by one author in the selected period. If article is published in more than one Publication, it is counted for each Publication'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorPageViewsPerArticleAggregateWidget = {
    title: 'Page Views / Article',
    hint: {
        avg: 'Average per Author number of Page Views per Article',
        max: 'Maximum per Author number of Page Views per Article',
        min: 'Minimum per Author number of Page Views per Article',
        default: 'Total per Author number of Page Views per Article',
        median: 'Median per Author number of Page Views per Article'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorAvgSessionDurationAggregateWidget = {
    defaultMetric: 'default',
    title: 'Avg. Session Duration',
    hint: {
        default: 'Avg. Session Duration for all Authors in the selected period',
        avg: 'Average Avg. Session Duration for all Authors in the selected period',
        max: 'Maximum Avg. Session Duration for all Authors in the selected period',
        min: 'Minimum Avg. Session Duration for all Authors in the selected period',
        median: 'Median Avg. Session Duration for all Authors in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorBusinessImpactEventsAggregateWidget = {
    defaultMetric: 'default',
    title: 'Business Impact Events',
    hint: {
        min: 'Minimum of Business Impact Events for all Authors in the selected period',
        max: 'Maximum of Business Impact Events for all Authors in the selected period',
        default:
            'Average of Business Impact Events for all Authors in the selected period',
        median: 'Median of Business Impact Events for all Authors in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorTotalSessionsDurationAggregateWidget = {
    defaultMetric: 'default',
    title: 'Total Sessions Duration',
    hint: {
        avg: 'Average Total Sessions Duration for all Authors in the selected period',
        max: 'Maximum Total Sessions Duration for all Authors in the selected period',
        min: 'Minimum Total Sessions Duration for all Authors in the selected period',
        median: 'Median Total Sessions Duration for all Authors in the selected period',
        default:
            'Total sessions duration for all Authors in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorCostPerUserAggregateWidget = {
    title: '$ / User',
    hint: {
        default: 'Cost per User',
        avg: 'Average per Author cost per User',
        max: 'Maximum per Author cost per User',
        min: 'Minimum per Author cost per User',
        sum: 'Total per Author cost per User',
        median: 'Median per Author cost per User'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorCostPerArticleAggregateWidget = {
    title: '$ / Article',
    hint: {
        default: 'Cost per Article',
        avg: 'Average per Author cost per Article',
        max: 'Maximum per Author cost per Article',
        min: 'Minimum per Author cost per Article',
        sum: 'Total per Author cost per Article',
        median: 'Median per Author cost per Article'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorSocialReferralsAggregateWidget = {
    title: 'Social Referrals',
    hint: {
        avg: 'Average number of Social Referrals per Author',
        max: 'Maximum number of Social Referrals per Author',
        min: 'Minimum number of Social Referrals per Author',
        default: 'Total number of Social Referrals for all Authors',
        median: 'Median number of Social Referrals per Author'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorSocialReferralsPerArticleAggregateWidget = {
    title: 'Social Referrals / Article',
    hint: {
        default:
            'Avg. Social Referrals / Article for all Authors in the selected period',
        max: 'Maximum Social Referrals / Article for all Authors in the selected period',
        min: 'Minimum Social Referrals / Article for all Authors in the selected period',
        median: 'Median Social Referrals / Article for all Authors in the selected period'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorCostPerSocialReferralAggregateWidget = {
    title: '$ / Social Referral',
    hint: {
        default: 'Cost per Social Referral',
        max: 'Maximum per Author cost per Social Referral',
        min: 'Minimum per Author cost per Social Referral',
        sum: 'Total per Author cost per Social Referral',
        median: 'Median per Author cost per Social Referral'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorSearchReferralsAggregateWidget = {
    title: 'Search Referrals',
    hint: {
        avg: 'Average number of search referrals per author',
        max: 'Maximum number of search referrals per author',
        min: 'Minimum number of search referrals per author',
        default: 'Total number of search referrals for all authors',
        median: 'Median number of search referrals per author'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorSearchReferralsPerArticleAggregateWidget = {
    title: 'Search Referrals / Article',
    hint: {
        default:
            'Search Referrals / Article for all authors in the selected period',
        max: 'Maximum Search Referrals / Article for all authors in the selected period',
        min: 'Minimum Search Referrals / Article for all authors in the selected period',
        median: 'Median Search Referrals / Article for all authors in the selected period'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorCostPerSearchReferralAggregateWidget = {
    title: '$ / Search Referral',
    hint: {
        default: 'Cost per Search Referral',
        avg: 'Average per Author cost per Search Referral',
        max: 'Maximum per Author cost per Search Referral',
        min: 'Minimum per Author cost per Search Referral',
        sum: 'Total per Author cost per Search Referral',
        median: 'Median per Author cost per Search Referral'
    },
    defaultMetric: 'default'
};

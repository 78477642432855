import { withWidget } from '../../withWidget.js';
import {
    AuthorColumn,
    AuthorUsersColumn,
    AuthorBySearchReferralsHistoryColumn,
    AuthorBySocialReferralsHistoryColumn,
    AuthorByViewsHistoryColumn,
    AuthorByVisitorsHistoryColumn,
    AuthorCostPerArticleColumn,
    AuthorCostPerPageViewColumn,
    AuthorCostPerSearchReferralColumn,
    AuthorCostPerSessionColumn,
    AuthorCostPerSocialReferralColumn,
    AuthorCostPerUserColumn,
    AuthorPageViewsColumn,
    AuthorPageViewsPerArticleColumn,
    AuthorSearchReferralsColumn,
    AuthorSearchReferralsPerArticleColumn,
    AuthorSessionsColumn,
    AuthorOrganicSessionsColumn,
    AuthorOrganicSessionsPerArticleColumn,
    AuthorSessionsPerArticleColumn,
    AuthorSocialReferralsColumn,
    AuthorSocialReferralsPerArticleColumn,
    ArticlesCountColumn,
    AuthorAvgSessionDurationColumn,
    UniqArticlesCountColumn,
    UniqArticlesCountHiddenColumn,
    AuthorUsersPerArticleColumn,
    AuthorTotalSessionsDurationColumn,
    AuthorBusinessImpactEventsColumn
} from '../../reports/editorial/author/defaultColumns.js';

import {
    AuthorArticlesCountAggregateWidget,
    AuthorAvgSessionDurationAggregateWidget,
    AuthorCostPerArticleAggregateWidget,
    AuthorCostPerSearchReferralAggregateWidget,
    AuthorCostPerSocialReferralAggregateWidget,
    AuthorCostPerUserAggregateWidget,
    AuthorPageViewsAggregateWidget,
    AuthorPageViewsPerArticleAggregateWidget,
    AuthorSearchReferralsAggregateWidget,
    AuthorSearchReferralsPerArticleAggregateWidget,
    AuthorSessionsAggregateWidget,
    AuthorSessionsPerArticleAggregateWidget,
    AuthorOrganicSessionsAggregateWidget,
    AuthorOrganicSessionsPerArticleAggregateWidget,
    AuthorSocialReferralsAggregateWidget,
    AuthorSocialReferralsPerArticleAggregateWidget,
    AuthorTotalSessionsDurationAggregateWidget,
    AuthorUsersAggregateWidget,
    AuthorUsersPerArticleAggregateWidget,
    AuthorBusinessImpactEventsAggregateWidget
} from '../../reports/editorial/author/aggregateWidgets.js';

import {
    ArticleColumn,
    ArticleBySearchReferralsHistoryColumn,
    ArticleBySocialReferralsHistoryColumn,
    ArticleByViewsHistoryColumn,
    ArticleByVisitorsHistoryColumn,
    ArticleCostPerArticleColumn,
    ArticleCostPerPageViewColumn,
    ArticleCostPerSearchReferralColumn,
    ArticleCostPerSocialReferralColumn,
    ArticleCostPerUserColumn,
    ArticlePageViewsColumn,
    ArticlePageViewsPerArticleColumn,
    ArticleSearchReferralsColumn,
    ArticleSessionsColumn,
    ArticleSessionsPerArticleColumn,
    ArticleOrganicSessionsColumn,
    ArticleOrganicSessionsPerArticleColumn,
    ArticleSocialReferralsColumn,
    ArticleUsersColumn,
    ArticleAvgSessionDurationColumn,
    ArticleTotalSessionsDurationColumn,
    ArticleUsersPerArticleColumn,
    ArticleBusinessImpactEventsColumn
} from '../../reports/editorial/article/defaultColumns.js';

import {
    ArticleArticlesCountAggregateWidget,
    ArticleUsersAggregateWidget,
    ArticleUsersPerArticleAggregateWidget,
    ArticleSessionsAggregateWidget,
    ArticleSessionsPerArticleAggregateWidget,
    ArticlePageViewsAggregateWidget,
    ArticlePageViewsPerArticleAggregateWidget,
    ArticleOrganicSessionsAggregateWidget,
    ArticleOrganicSessionsPerArticleAggregateWidget,
    ArticleCostPerArticleAggregateWidget,
    ArticleCostPerUserAggregateWidget,
    ArticleBusinessImpactEventsAggregateWidget,
    ArticleAvgSessionDurationAggregateWidget,
    ArticleTotalSessionsDurationAggregateWidget
} from '../../reports/editorial/article/aggregateWidgets.js';

import {
    MetadataReferralsArticlesCountColumn,
    MetadataReferralsPageViewsColumn,
    MetadataReferralsPageViewsHistoryColumn,
    MetadataReferralsNewVisitorsColumn,
    MetadataReferralsNewVisitorsHistoryColumn,
    MetadataReferralsSessionsColumn,
    MetadataReferralsSessionsHistoryColumn,
    MetadataReferralsUsersColumn,
    MetadataReferralsUsersPerArticleColumn,
    MetadataReferralsSessionsPerArticleColumn,
    MetadataReferralsPageViewsPerArticleColumn
} from '../../reports/editorial/metadata/defaultColumns.js';

import {
    MetadataReferralsArticlesCountAggregateWidget,
    MetadataReferralsUsersAggregateWidget,
    MetadataReferralsUsersPerArticleAggregateWidget,
    MetadataReferralsSessionsAggregateWidget,
    MetadataReferralsSessionsPerArticleAggregateWidget,
    MetadataReferralsPageViewsAggregateWidget,
    MetadataReferralsPageViewsPerArticleAggregateWidget
} from '../../reports/editorial/metadata/aggregateWidgets.js';

export const EMPTY_GROUP_BY_KEY = 'none';

// metadata report has the same columns for every showBy
const MetadataColumns = [
    withWidget(
        MetadataReferralsArticlesCountColumn,
        MetadataReferralsArticlesCountAggregateWidget,
        1,
        true
    ),
    withWidget(
        MetadataReferralsUsersColumn,
        MetadataReferralsUsersAggregateWidget,
        2,
        true
    ),
    withWidget(
        MetadataReferralsUsersPerArticleColumn,
        MetadataReferralsUsersPerArticleAggregateWidget,
        3,
        true
    ),
    withWidget(
        MetadataReferralsSessionsPerArticleColumn,
        MetadataReferralsSessionsPerArticleAggregateWidget,
        5,
        true
    ),
    withWidget(
        MetadataReferralsPageViewsColumn,
        MetadataReferralsPageViewsAggregateWidget,
        6
    ),
    withWidget(
        MetadataReferralsPageViewsPerArticleColumn,
        MetadataReferralsPageViewsPerArticleAggregateWidget,
        7,
        true
    ),
    MetadataReferralsPageViewsHistoryColumn,
    MetadataReferralsNewVisitorsColumn,
    MetadataReferralsNewVisitorsHistoryColumn,
    withWidget(
        MetadataReferralsSessionsColumn,
        MetadataReferralsSessionsAggregateWidget,
        4
    ),
    MetadataReferralsSessionsHistoryColumn
];

/**
 * @type {Record<import('smg-iq').EditorialReportSpecification['type'], Record<import('smg-iq').EditorialReportSpecification['showBy'], Record<import('smg-iq').EditorialReportSpecification['groupBy'] | 'none',  import('smg-iq').ReportColumnWithGroup[]>>}
 */
export const DefaultReportColumns = {
    author: {
        visitors: {
            none: [
                AuthorColumn,
                withWidget(AuthorUsersColumn, AuthorUsersAggregateWidget, 2),
                withWidget(
                    AuthorUsersPerArticleColumn,
                    AuthorUsersPerArticleAggregateWidget,
                    3
                ),
                withWidget(
                    AuthorSessionsColumn,
                    AuthorSessionsAggregateWidget,
                    4
                ),
                withWidget(
                    AuthorSessionsPerArticleColumn,
                    AuthorSessionsPerArticleAggregateWidget,
                    5,
                    true
                ),
                withWidget(
                    AuthorOrganicSessionsColumn,
                    AuthorOrganicSessionsAggregateWidget,
                    6
                ),
                withWidget(
                    AuthorOrganicSessionsPerArticleColumn,
                    AuthorOrganicSessionsPerArticleAggregateWidget,
                    7
                ),
                withWidget(
                    AuthorPageViewsColumn,
                    AuthorPageViewsAggregateWidget,
                    8
                ),
                withWidget(
                    ArticlesCountColumn,
                    AuthorArticlesCountAggregateWidget,
                    1
                ),
                withWidget(
                    AuthorPageViewsPerArticleColumn,
                    AuthorPageViewsPerArticleAggregateWidget,
                    9,
                    true
                ),
                UniqArticlesCountColumn,
                withWidget(
                    AuthorAvgSessionDurationColumn,
                    AuthorAvgSessionDurationAggregateWidget,
                    10
                ),
                withWidget(
                    AuthorTotalSessionsDurationColumn,
                    AuthorTotalSessionsDurationAggregateWidget,
                    11
                ),
                withWidget(
                    AuthorCostPerUserColumn,
                    AuthorCostPerUserAggregateWidget,
                    9
                ),
                AuthorCostPerSessionColumn,
                withWidget(
                    AuthorCostPerArticleColumn,
                    AuthorCostPerArticleAggregateWidget,
                    8
                ),
                withWidget(
                    AuthorBusinessImpactEventsColumn,
                    AuthorBusinessImpactEventsAggregateWidget,
                    12,
                    false
                ),
                AuthorByVisitorsHistoryColumn
            ]
        },
        views: {
            none: [
                AuthorColumn,
                withWidget(AuthorUsersColumn, AuthorUsersAggregateWidget, 2),
                withWidget(
                    AuthorUsersPerArticleColumn,
                    AuthorUsersPerArticleAggregateWidget,
                    3
                ),
                withWidget(
                    AuthorSessionsColumn,
                    AuthorSessionsAggregateWidget,
                    4
                ),
                withWidget(
                    AuthorSessionsPerArticleColumn,
                    AuthorSessionsPerArticleAggregateWidget,
                    5,
                    true
                ),
                withWidget(
                    AuthorOrganicSessionsColumn,
                    AuthorOrganicSessionsAggregateWidget,
                    6
                ),
                withWidget(
                    AuthorOrganicSessionsPerArticleColumn,
                    AuthorOrganicSessionsPerArticleAggregateWidget,
                    7
                ),
                withWidget(
                    AuthorPageViewsColumn,
                    AuthorPageViewsAggregateWidget,
                    8
                ),
                withWidget(
                    ArticlesCountColumn,
                    AuthorArticlesCountAggregateWidget,
                    1
                ),
                withWidget(
                    AuthorPageViewsPerArticleColumn,
                    AuthorPageViewsPerArticleAggregateWidget,
                    9,
                    true
                ),
                UniqArticlesCountColumn,
                withWidget(
                    AuthorAvgSessionDurationColumn,
                    AuthorAvgSessionDurationAggregateWidget,
                    10
                ),
                withWidget(
                    AuthorTotalSessionsDurationColumn,
                    AuthorTotalSessionsDurationAggregateWidget,
                    11,
                    true
                ),
                AuthorCostPerSessionColumn,
                AuthorCostPerPageViewColumn,
                withWidget(
                    AuthorCostPerArticleColumn,
                    AuthorCostPerArticleAggregateWidget,
                    12
                ),
                withWidget(
                    AuthorCostPerUserColumn,
                    AuthorCostPerUserAggregateWidget,
                    13,
                    true
                ),
                withWidget(
                    AuthorBusinessImpactEventsColumn,
                    AuthorBusinessImpactEventsAggregateWidget,
                    14,
                    false
                ),
                AuthorByViewsHistoryColumn
            ]
        },
        social_referrals: {
            none: [
                AuthorColumn,
                withWidget(AuthorUsersColumn, AuthorUsersAggregateWidget, 2),
                withWidget(
                    AuthorUsersPerArticleColumn,
                    AuthorUsersPerArticleAggregateWidget,
                    3
                ),
                withWidget(
                    AuthorSessionsColumn,
                    AuthorSessionsAggregateWidget,
                    4
                ),
                withWidget(
                    AuthorOrganicSessionsColumn,
                    AuthorOrganicSessionsAggregateWidget,
                    5
                ),
                withWidget(
                    AuthorOrganicSessionsPerArticleColumn,
                    AuthorOrganicSessionsPerArticleAggregateWidget,
                    6
                ),
                AuthorPageViewsColumn,
                withWidget(
                    ArticlesCountColumn,
                    AuthorArticlesCountAggregateWidget,
                    1
                ),
                UniqArticlesCountColumn,
                withWidget(
                    AuthorSocialReferralsColumn,
                    AuthorSocialReferralsAggregateWidget,
                    7
                ),
                withWidget(
                    AuthorSocialReferralsPerArticleColumn,
                    AuthorSocialReferralsPerArticleAggregateWidget,
                    8
                ),
                withWidget(
                    AuthorCostPerSocialReferralColumn,
                    AuthorCostPerSocialReferralAggregateWidget,
                    9
                ),
                AuthorCostPerSessionColumn,
                withWidget(
                    AuthorCostPerArticleColumn,
                    AuthorCostPerArticleAggregateWidget,
                    10
                ),
                withWidget(
                    AuthorCostPerUserColumn,
                    AuthorCostPerUserAggregateWidget,
                    11,
                    true
                ),
                withWidget(
                    AuthorAvgSessionDurationColumn,
                    AuthorAvgSessionDurationAggregateWidget,
                    12,
                    true
                ),
                withWidget(
                    AuthorTotalSessionsDurationColumn,
                    AuthorTotalSessionsDurationAggregateWidget,
                    13,
                    true
                ),
                withWidget(
                    AuthorBusinessImpactEventsColumn,
                    AuthorBusinessImpactEventsAggregateWidget,
                    14,
                    false
                ),
                AuthorBySocialReferralsHistoryColumn
            ]
        },
        search_referrals: {
            none: [
                AuthorColumn,
                withWidget(AuthorUsersColumn, AuthorUsersAggregateWidget, 2),
                withWidget(
                    AuthorUsersPerArticleColumn,
                    AuthorUsersPerArticleAggregateWidget,
                    3
                ),
                withWidget(
                    AuthorSessionsColumn,
                    AuthorSessionsAggregateWidget,
                    4
                ),
                withWidget(
                    AuthorOrganicSessionsColumn,
                    AuthorOrganicSessionsAggregateWidget,
                    5
                ),
                withWidget(
                    AuthorOrganicSessionsPerArticleColumn,
                    AuthorOrganicSessionsPerArticleAggregateWidget,
                    6
                ),
                AuthorPageViewsColumn,
                withWidget(
                    ArticlesCountColumn,
                    AuthorArticlesCountAggregateWidget,
                    1
                ),
                UniqArticlesCountColumn,
                withWidget(
                    AuthorSearchReferralsColumn,
                    AuthorSearchReferralsAggregateWidget,
                    7
                ),
                withWidget(
                    AuthorSearchReferralsPerArticleColumn,
                    AuthorSearchReferralsPerArticleAggregateWidget,
                    8
                ),
                withWidget(
                    AuthorCostPerSearchReferralColumn,
                    AuthorCostPerSearchReferralAggregateWidget,
                    9
                ),
                AuthorCostPerSessionColumn,
                withWidget(
                    AuthorCostPerArticleColumn,
                    AuthorCostPerArticleAggregateWidget,
                    7
                ),
                withWidget(
                    AuthorCostPerUserColumn,
                    AuthorCostPerUserAggregateWidget,
                    8,
                    true
                ),
                withWidget(
                    AuthorAvgSessionDurationColumn,
                    AuthorAvgSessionDurationAggregateWidget,
                    10,
                    true
                ),
                withWidget(
                    AuthorTotalSessionsDurationColumn,
                    AuthorTotalSessionsDurationAggregateWidget,
                    11,
                    true
                ),
                withWidget(
                    AuthorBusinessImpactEventsColumn,
                    AuthorBusinessImpactEventsAggregateWidget,
                    12,
                    false
                ),
                AuthorBySearchReferralsHistoryColumn
            ]
        }
    },
    content: {
        visitors: {
            none: [
                withWidget(
                    ArticlesCountColumn,
                    ArticleArticlesCountAggregateWidget,
                    1,
                    true
                ),
                ArticleColumn,
                UniqArticlesCountHiddenColumn,
                withWidget(ArticleUsersColumn, ArticleUsersAggregateWidget, 2),
                withWidget(
                    ArticleUsersPerArticleColumn,
                    ArticleUsersPerArticleAggregateWidget,
                    3,
                    true
                ),
                withWidget(
                    ArticleSessionsColumn,
                    ArticleSessionsAggregateWidget,
                    4
                ),
                withWidget(
                    ArticleSessionsPerArticleColumn,
                    ArticleSessionsPerArticleAggregateWidget,
                    5,
                    true
                ),
                withWidget(
                    ArticleOrganicSessionsColumn,
                    ArticleOrganicSessionsAggregateWidget,
                    6
                ),
                withWidget(
                    ArticleOrganicSessionsPerArticleColumn,
                    ArticleOrganicSessionsPerArticleAggregateWidget,
                    7,
                    true
                ),
                withWidget(
                    ArticlePageViewsColumn,
                    ArticlePageViewsAggregateWidget,
                    8
                ),
                withWidget(
                    ArticlePageViewsPerArticleColumn,
                    ArticlePageViewsPerArticleAggregateWidget,
                    9,
                    true
                ),
                withWidget(
                    ArticleAvgSessionDurationColumn,
                    ArticleAvgSessionDurationAggregateWidget,
                    10
                ),
                withWidget(
                    ArticleTotalSessionsDurationColumn,
                    ArticleTotalSessionsDurationAggregateWidget,
                    11
                ),
                withWidget(
                    ArticleCostPerUserColumn,
                    ArticleCostPerUserAggregateWidget,
                    9
                ),
                withWidget(
                    ArticleCostPerArticleColumn,
                    ArticleCostPerArticleAggregateWidget,
                    8
                ),
                withWidget(
                    ArticleBusinessImpactEventsColumn,
                    ArticleBusinessImpactEventsAggregateWidget,
                    12,
                    false
                ),
                ArticleByVisitorsHistoryColumn
            ]
        },
        views: {
            none: [
                withWidget(
                    ArticlesCountColumn,
                    ArticleArticlesCountAggregateWidget,
                    1,
                    true
                ),
                ArticleColumn,
                UniqArticlesCountHiddenColumn,
                withWidget(ArticleUsersColumn, ArticleUsersAggregateWidget, 2),
                withWidget(
                    ArticleUsersPerArticleColumn,
                    ArticleUsersPerArticleAggregateWidget,
                    3,
                    true
                ),
                withWidget(
                    ArticleSessionsColumn,
                    ArticleSessionsAggregateWidget,
                    4
                ),
                withWidget(
                    ArticleSessionsPerArticleColumn,
                    ArticleSessionsPerArticleAggregateWidget,
                    5,
                    true
                ),
                withWidget(
                    ArticleOrganicSessionsColumn,
                    ArticleOrganicSessionsAggregateWidget,
                    6
                ),
                withWidget(
                    ArticleOrganicSessionsPerArticleColumn,
                    ArticleOrganicSessionsPerArticleAggregateWidget,
                    7,
                    true
                ),
                withWidget(
                    ArticlePageViewsColumn,
                    ArticlePageViewsAggregateWidget,
                    8
                ),
                withWidget(
                    ArticlePageViewsPerArticleColumn,
                    ArticlePageViewsPerArticleAggregateWidget,
                    9,
                    true
                ),
                withWidget(
                    ArticleAvgSessionDurationColumn,
                    ArticleAvgSessionDurationAggregateWidget,
                    10
                ),
                withWidget(
                    ArticleTotalSessionsDurationColumn,
                    ArticleTotalSessionsDurationAggregateWidget,
                    11
                ),
                ArticleCostPerPageViewColumn,
                withWidget(
                    ArticleCostPerArticleColumn,
                    ArticleCostPerArticleAggregateWidget,
                    10
                ),
                withWidget(
                    ArticleCostPerUserColumn,
                    ArticleCostPerUserAggregateWidget,
                    11,
                    true
                ),
                withWidget(
                    ArticleBusinessImpactEventsColumn,
                    ArticleBusinessImpactEventsAggregateWidget,
                    12,
                    false
                ),
                ArticleByViewsHistoryColumn
            ]
        },
        social_referrals: {
            none: [
                withWidget(
                    ArticlesCountColumn,
                    ArticleArticlesCountAggregateWidget,
                    1,
                    true
                ),
                ArticleColumn,
                UniqArticlesCountHiddenColumn,
                withWidget(ArticleUsersColumn, ArticleUsersAggregateWidget, 2),
                withWidget(
                    ArticleUsersPerArticleColumn,
                    ArticleUsersPerArticleAggregateWidget,
                    3,
                    true
                ),
                withWidget(
                    ArticleSessionsColumn,
                    ArticleSessionsAggregateWidget,
                    4
                ),
                withWidget(
                    ArticleSessionsPerArticleColumn,
                    ArticleSessionsPerArticleAggregateWidget,
                    5,
                    true
                ),
                withWidget(
                    ArticleOrganicSessionsColumn,
                    ArticleOrganicSessionsAggregateWidget,
                    6
                ),
                withWidget(
                    ArticleOrganicSessionsPerArticleColumn,
                    ArticleOrganicSessionsPerArticleAggregateWidget,
                    7,
                    true
                ),
                withWidget(
                    ArticlePageViewsColumn,
                    ArticlePageViewsAggregateWidget,
                    8
                ),
                withWidget(
                    ArticlePageViewsPerArticleColumn,
                    ArticlePageViewsPerArticleAggregateWidget,
                    9,
                    true
                ),
                withWidget(
                    ArticleAvgSessionDurationColumn,
                    ArticleAvgSessionDurationAggregateWidget,
                    10
                ),
                withWidget(
                    ArticleTotalSessionsDurationColumn,
                    ArticleTotalSessionsDurationAggregateWidget,
                    11
                ),
                ArticleSocialReferralsColumn,
                ArticleCostPerSocialReferralColumn,
                withWidget(
                    ArticleCostPerArticleColumn,
                    ArticleCostPerArticleAggregateWidget,
                    10
                ),
                withWidget(
                    ArticleCostPerUserColumn,
                    ArticleCostPerUserAggregateWidget,
                    11,
                    true
                ),
                withWidget(
                    ArticleBusinessImpactEventsColumn,
                    ArticleBusinessImpactEventsAggregateWidget,
                    12,
                    false
                ),
                ArticleBySocialReferralsHistoryColumn
            ]
        },
        search_referrals: {
            none: [
                ArticleColumn,
                withWidget(
                    ArticlesCountColumn,
                    ArticleArticlesCountAggregateWidget,
                    1,
                    true
                ),
                UniqArticlesCountHiddenColumn,
                withWidget(ArticleUsersColumn, ArticleUsersAggregateWidget, 2),
                withWidget(
                    ArticleUsersPerArticleColumn,
                    ArticleUsersPerArticleAggregateWidget,
                    3,
                    true
                ),
                withWidget(
                    ArticleSessionsColumn,
                    ArticleSessionsAggregateWidget,
                    4
                ),
                withWidget(
                    ArticleSessionsPerArticleColumn,
                    ArticleSessionsPerArticleAggregateWidget,
                    5,
                    true
                ),
                withWidget(
                    ArticleOrganicSessionsColumn,
                    ArticleOrganicSessionsAggregateWidget,
                    6
                ),
                withWidget(
                    ArticleOrganicSessionsPerArticleColumn,
                    ArticleOrganicSessionsPerArticleAggregateWidget,
                    7,
                    true
                ),
                withWidget(
                    ArticlePageViewsColumn,
                    ArticlePageViewsAggregateWidget,
                    8
                ),
                withWidget(
                    ArticlePageViewsPerArticleColumn,
                    ArticlePageViewsPerArticleAggregateWidget,
                    9,
                    true
                ),
                withWidget(
                    ArticleAvgSessionDurationColumn,
                    ArticleAvgSessionDurationAggregateWidget,
                    10
                ),
                withWidget(
                    ArticleTotalSessionsDurationColumn,
                    ArticleTotalSessionsDurationAggregateWidget,
                    11
                ),
                ArticleSearchReferralsColumn,
                ArticleCostPerSearchReferralColumn,
                withWidget(
                    ArticleCostPerArticleColumn,
                    ArticleCostPerArticleAggregateWidget,
                    12
                ),
                withWidget(
                    ArticleCostPerUserColumn,
                    ArticleCostPerUserAggregateWidget,
                    13,
                    true
                ),
                withWidget(
                    ArticleBusinessImpactEventsColumn,
                    ArticleBusinessImpactEventsAggregateWidget,
                    14,
                    false
                ),
                ArticleBySearchReferralsHistoryColumn
            ]
        }
    },
    metadata: {
        visitors: {
            none: MetadataColumns
        },
        views: {
            none: MetadataColumns
        },
        search_referrals: {
            none: MetadataColumns
        },
        social_referrals: {
            none: MetadataColumns
        }
    }
};

export const DefaultReportColumnsMap = {
    'default.users': 'Users',
    'default.usersPerArticle': 'Users per Article',
    'default.sessions': 'Sessions',
    'default.sessionsPerArticle': 'Sessions per Article',
    'default.organicSessions': 'Organic Sessions',
    'default.organicSessionsPerArticle': 'Organic Sessions per Article',
    'default.pageViews': 'Page Views',
    'default.articlesCount': 'Articles',
    'default.pageViewsPerArticle': 'Page Views per Article',
    'default.uniqArticlesCount': 'Unique Articles',
    'default.avgSessionDuration': 'Avg. Session Duration',
    'default.totalSessionsDuration': 'Total Sessions Duration',
    'default.costPerUser': 'Cost per User',
    'default.costPerSession': 'Cost per Session',
    'default.costPerArticle': 'Cost per Article',
    'default.costPerPageView': 'Cost per Page View',
    'default.socialReferrals': 'Social Referrals',
    'default.socialReferralsPerArticle': 'Social Referrals per Article',
    'default.costPerSocialReferral': 'Cost per Social Referral',
    'default.searchReferrals': 'Search Referrals',
    'default.searchReferralsPerArticle': 'Search Referrals per Article',
    'default.costPerSearchReferral': 'Cost per Search Referral'
};

/**
 *
 * @param {{
 *      type: import('smg-iq').EditorialReportSpecification['type'];
 *      showBy: import('smg-iq').EditorialReportSpecification['showBy'];
 *      groupBy: import('smg-iq').EditorialReportSpecification['groupBy'];
 *      columnGroups: import('smg-iq').EditorialReportSpecification['columnGroups'];
 * }} spec
 * @param {import('smg-iq').ISimpleColumnGroupsRepository} columnGroupsRepository
 * @param {boolean} [onlySortable]
 * @returns {Promise<import('smg-iq').ReportColumnWithGroup[]>}
 */
export const getPossibleSortColumns = async (
    spec,
    columnGroupsRepository,
    onlySortable
) => {
    const res = await columnGroupsRepository.getReportColumns(
        'editorial',
        spec
    );
    return res.filter(
        (c) =>
            (onlySortable ? c.column.sortable : true) && !c.column.hideFromTable
    );
};

/**
 * @param {import('smg-iq').EditorialReportSpecification} specification
 * @returns {boolean}
 */
export const isTableTypeReport = (specification) =>
    ['author', 'content'].includes(specification?.type);

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.author',
        apiName: 'author',
        format: 'author',
        label: 'Author',
        sortable: true
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorUsersColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.users',
        apiName: 'users',
        format: 'integer',
        label: 'Users',
        hint: "Count of Uniq Users who visited Author's Articles in the selected period",
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorSessionsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.sessions',
        apiName: 'sessions',
        format: 'integer',
        label: 'Sessions',
        hint: "Number of Uniq Sessions where Author's Articles were viewed in the selected period",
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorOrganicSessionsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.organicSessions',
        apiName: 'organicSessions',
        format: 'integer',
        label: 'Organic Sessions',
        hint: "Number of Uniq Organic Sessions (Session Channel = 'Organic Search') where Author's Articles were viewed in the selected period",
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorOrganicSessionsPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.organicSessionsPerArticle',
        apiName: 'organicSessionsPerArticle',
        format: 'decimal',
        label: 'Organic Sessions / Article',
        hint: "Number of Uniq Organic Sessions (Session Channel = 'Organic Search') where Author's Articles were viewed in the selected period divided by the number of Articles",
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorSessionsPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.sessionsPerArticle',
        apiName: 'sessionsPerArticle',
        format: 'decimal',
        label: 'Sessions / Article',
        hint: "Number of Uniq Sessions where Author's Articles were viewed in the selected period divided by the number of Articles",
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorPageViewsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.pageViews',
        apiName: 'pageViews',
        format: 'integer',
        label: 'Page Views',
        hint: "Number of Uniq Page Views of Author's Articles in the selected period",
        sortable: true,
        isDefaultSortColumn: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorSocialReferralsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.socialReferrals',
        apiName: 'socialReferrals',
        format: 'integer',
        label: 'Social Refs',
        hint: "Number of Uniq Social Referrals to Author's Articles in the selected period",
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorSearchReferralsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.searchReferrals',
        apiName: 'searchReferrals',
        format: 'integer',
        label: 'Search Refs',
        hint: "Number of Organic Search referrals to Author's Articles in the selected period",
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorSocialReferralsPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.socialReferralsPerArticle',
        apiName: 'socialReferralsPerArticle',
        format: 'decimal',
        label: 'Social refs / Article',
        hint: "Number of Social Referrals to Author's Articles in the selected period divided by the number of Articles",
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorSearchReferralsPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.searchReferralsPerArticle',
        apiName: 'searchReferralsPerArticle',
        format: 'decimal',
        label: 'Search refs / Article',
        hint: "Number of Organic Search referrals to Author's Articles in the selected period divided by the number of Articles",
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorPageViewsPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.pageViewsPerArticle',
        apiName: 'pageViewsPerArticle',
        format: 'decimal',
        label: 'Page Views / Article',
        hint: "Number of Uniq Page Views of Author's Articles in the selected period divided by the number of Articles",
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorBusinessImpactEventsColumn = {
    column: {
        hint: 'Business Impact events (BusinessImpact and legacy business_impact)',
        type: 'event',
        label: 'BI Events',
        format: 'integer',
        apiName: 'businessImpactEvents',
        sortable: true,
        eventName: {
            value: ['BusinessImpact', 'business_impact'],
            operation: 'in'
        },
        calculation: 'count',
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorCostPerUserColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerUser',
        apiName: 'costPerUser',
        format: 'money',
        label: '$ / User',
        hint: "Total cost of Author's articles viewed in the selected period divided by the number of users who viewed the articles",
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorCostPerSessionColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerSession',
        apiName: 'costPerSession',
        format: 'money',
        label: '$ / Session',
        hint: "Total cost of Author's articles viewed in the selected period divided by the number of sessions where the articles were viewed",
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorCostPerSearchReferralColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerSearchReferral',
        apiName: 'costPerSearchReferral',
        format: 'money',
        label: '$ / Search Ref',
        hint: "Total cost of Author's articles viewed in the selected period divided by the number of search referrals to the articles",
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorCostPerPageViewColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerPageView',
        apiName: 'costPerPageView',
        format: 'money',
        label: '$ / Page View',
        hint: "Total cost of Author's articles viewed in the selected period divided by the number of page views of the articles",
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorCostPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerArticle',
        apiName: 'costPerArticle',
        format: 'money',
        label: '$ / Article',
        hint: "Total cost of Author's articles viewed in the selected period divided by the number of Articles",
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorCostPerSocialReferralColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerSocialReferral',
        apiName: 'costPerSocialReferral',
        format: 'money',
        label: '$ / Social Ref',
        hint: "Total cost of Author's articles viewed in the selected period divided by the number of social referrals to the articles",
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorByVisitorsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.users',
        apiName: 'usersHistory',
        format: 'history',
        label: 'Users Trend',
        hint: 'History of the number of Visitors to the Author’s Articles in the selected period. Bars are matching your selection in the top chart.',
        sortable: false,
        pushToRight: true,
        groupByOperation: 'sumForEach'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorByViewsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.pageViews',
        apiName: 'pageViewsHistory',
        format: 'history',
        label: 'Page Views Trend',
        hint: 'History of the page views on the Author’s Articles in the selected period. Bars are matching your selection in the top chart.',
        sortable: false,
        pushToRight: true,
        groupByOperation: 'sumForEach'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorBySocialReferralsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.socialReferrals',
        apiName: 'socialReferralsHistory',
        format: 'history',
        label: 'Social Referrals Trend',
        hint: 'History of the social referrals on the Author’s Articles in the selected period. Bars are matching your selection in the top chart.',
        sortable: false,
        pushToRight: true,
        groupByOperation: 'sumForEach'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorBySearchReferralsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.searchReferrals',
        apiName: 'searchReferralsHistory',
        format: 'history',
        label: 'Search Referrals Trend',
        hint: 'History of the Organic Search referrals on the Author’s Articles in the selected period. Bars are matching your selection in the top chart.',
        sortable: false,
        pushToRight: true,
        groupByOperation: 'sumForEach'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticlesCountColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.articlesCount',
        apiName: 'articlesCount',
        format: 'integer',
        label: 'Articles',
        hint: 'Number of Articles written by the Author in the selected period. If Article is published in more than one Publication, it is counted for each Publication',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const UniqArticlesCountColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.uniqArticlesCount',
        apiName: 'uniqArticlesCount',
        format: 'integer',
        label: 'Uniq Articles',
        hint: 'Number of unique Articles written by the Author in the selected period. If Article is published in more than one Publication, it is counted only once',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const UniqArticlesCountHiddenColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.uniqArticlesCount',
        apiName: 'uniqArticlesCount',
        format: 'integer',
        label: 'Uniq Articles',
        hint: 'Number of unique Articles written by the Author in the selected period. If Article is published in more than one Publication, it is counted only once',
        sortable: true,
        groupByOperation: 'sum',
        hideFromTable: true
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorUsersPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.usersPerArticle',
        apiName: 'usersPerArticle',
        format: 'decimal',
        label: 'Users / Article',
        hint: 'Number of Uniq Users who visited the Article in the selected period divided by the number of Articles',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorAvgSessionDurationColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.avgSessionDuration',
        apiName: 'avgSessionDuration',
        format: 'time',
        label: 'Avg. Session Duration',
        hint: 'Average duration of a session in articles written by the author in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const AuthorTotalSessionsDurationColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.totalSessionsDuration',
        apiName: 'totalSessionsDuration',
        format: 'time',
        label: 'Total Sessions Duration',
        hint: 'Total duration of all sessions where the Articles published by the Author were viewed in the selected period',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

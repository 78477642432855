/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleArticlesCountAggregateWidget = {
    defaultMetric: 'default',
    title: 'Articles',
    hint: {
        default:
            'Total number of Articles written by all authors in the selected period. If article is published in more than one Publication, it is counted for each Publication'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleUsersAggregateWidget = {
    defaultMetric: 'default',
    title: 'Users',
    hint: {
        default: 'Total number of Uniq Users in the selected period',
        sum: 'Sum of Uniq Users per Article in the selected period',
        avg: 'Average number of Uniq Users per Article in the selected period',
        max: 'Maximum number of Uniq Users per one Article in the selected period',
        min: 'Minimum number of Uniq Users per one Article in the selected period',
        median: 'Median number of Uniq Users per Article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleUsersPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Users / Article',
    hint: {
        min: 'Minimum number of Uniq Users per one Article in the selected period',
        max: 'Maximum number of Uniq Users per one Article in the selected period',
        avg: 'Average number of Uniq Users per Article in the selected period',
        default:
            'Total number of Uniq Users per Article in the selected period',
        median: 'Median number of Uniq Users per Article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleSessionsAggregateWidget = {
    defaultMetric: 'default',
    title: 'Sessions',
    hint: {
        default: 'Total number of Sessions in the selected period',
        avg: 'Average number of Sessions per Article in the selected period',
        max: 'Maximum number of Sessions per one Article in the selected period',
        min: 'Minimum number of Sessions per one Article in the selected period',
        median: 'Median number of Sessions per Article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleSessionsPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Sessions / Article',
    hint: {
        min: 'Minimum Sessions for one Article in the selected period',
        max: 'Maximum Sessions for one Article in the selected period',
        default: 'Average Sessions for one Article in the selected period',
        median: 'Median Sessions for one Article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleOrganicSessionsAggregateWidget = {
    defaultMetric: 'default',
    title: 'Organic Sessions',
    hint: {
        default: 'Total number of Organic Sessions in the selected period',
        avg: 'Average number of Organic Sessions per Article in the selected period',
        max: 'Maximum number of Organic Sessions per one Article in the selected period',
        min: 'Minimum number of Organic Sessions per one Article in the selected period',
        median: 'Median number of Organic Sessions per Article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleOrganicSessionsPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Organic Sessions / Article',
    hint: {
        avg: 'Average per Article number of Organic Sessions',
        max: 'Maximum per Article number of Organic Sessions',
        min: 'Minimum per Article number of Organic Sessions',
        default: 'Total per Article number of Organic Sessions',
        median: 'Median per Article number of Organic Sessions'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticlePageViewsAggregateWidget = {
    defaultMetric: 'default',
    title: 'Page Views',
    hint: {
        default: 'Total number of Page Views in the selected period',
        sum: 'Sum of Page Views among Articles in the selected period',
        avg: 'Average number of Page Views per Article in the selected period',
        max: 'Max Page Views of one Article in the selected period',
        min: 'Min Page Views of one Article in the selected period',
        median: 'Median Page Views of one Article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticlePageViewsPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Page Views / Article',
    hint: {
        min: 'Minimum Page Views for one Article in the selected period',
        max: 'Maximum Page Views for one Article in the selected period',
        default: 'Average Page Views per Article in the selected period',
        median: 'Median Page Views for one Article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleCostPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Cost / Article',
    hint: {
        min: 'Minimum cost of one Article in the selected period',
        max: 'Maximum cost of one Article in the selected period',
        default: 'Average cost of one Article in the selected period',
        median: 'Median cost of one Article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleCostPerUserAggregateWidget = {
    defaultMetric: 'default',
    title: 'Cost / User',
    hint: {
        min: 'Minimum cost per User in the selected period',
        max: 'Maximum cost per User in the selected period',
        default: 'Average cost per User in the selected period',
        median: 'Median cost per User in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleAvgSessionDurationAggregateWidget = {
    defaultMetric: 'default',
    title: 'Avg Session Duration',
    hint: {
        min: 'Minimum Average Session Duration for one Article in the selected period',
        max: 'Maximum Average Session Duration for one Article in the selected period',
        default:
            'Average Average Session Duration for one Article in the selected period',
        median: 'Median Average Session Duration for one Article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleBusinessImpactEventsAggregateWidget = {
    defaultMetric: 'default',
    title: 'Business Impact Events',
    hint: {
        min: 'Minimum of Business Impact Events for one Article in the selected period',
        max: 'Maximum of Business Impact Events for one Article in the selected period',
        default:
            'Average of Business Impact Events for one Article in the selected period',
        median: 'Median of Business Impact Events for one Article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleTotalSessionsDurationAggregateWidget = {
    defaultMetric: 'default',
    title: 'Total Sessions Duration',
    hint: {
        min: 'Minimum Total Sessions Duration for one Article in the selected period',
        max: 'Maximum Total Sessions Duration for one Article in the selected period',
        default:
            'Average Total Sessions Duration for one Article in the selected period',
        median: 'Median Total Sessions Duration for one Article in the selected period',
        sum: 'Total Total Sessions Duration for all Articles in the selected period'
    }
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.article',
        apiName: 'article',
        format: 'article',
        label: 'Article',
        sortable: false
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleUsersColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.users',
        apiName: 'users',
        format: 'integer',
        label: 'Users',
        hint: 'Count of Uniq Users who visited the Article in the selected period',
        sortable: true,
        isDefaultSortColumn: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleSessionsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.sessions',
        apiName: 'sessions',
        format: 'integer',
        label: 'Sessions',
        hint: 'Number of Sessions where the Article was viewed in the selected period',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleSessionsPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.sessionsPerArticle',
        apiName: 'sessionsPerArticle',
        format: 'decimal',
        label: 'Sessions / Article',
        hint: 'Average number of Sessions per Article in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleOrganicSessionsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.organicSessions',
        apiName: 'organicSessions',
        format: 'integer',
        label: 'Organic Sessions',
        hint: 'Number of Organic Sessions (Session Channel = "Organic Search") where the Article was viewed in the selected period',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleOrganicSessionsPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.organicSessionsPerArticle',
        apiName: 'organicSessionsPerArticle',
        format: 'decimal',
        label: 'Organic Sessions / Article',
        hint: 'Average number of Organic Sessions (Session Channel = "Organic Search") per Article in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticlePageViewsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.pageViews',
        apiName: 'pageViews',
        format: 'integer',
        label: 'Page Views',
        hint: 'Number of Page Views of the Article in the selected period',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticlePageViewsPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.pageViewsPerArticle',
        apiName: 'pageViewsPerArticle',
        format: 'decimal',
        label: 'Page Views / Article',
        hint: 'Average number of Page Views per Article in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleSocialReferralsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.socialReferrals',
        apiName: 'socialReferrals',
        format: 'integer',
        label: 'Social Refs',
        hint: 'Number of Social Referrals to the Article in the selected period',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleSearchReferralsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.searchReferrals',
        apiName: 'searchReferrals',
        format: 'integer',
        label: 'Search Refs',
        hint: 'Number of Search Referrals to the Article in the selected period',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleBusinessImpactEventsColumn = {
    column: {
        hint: 'Business Impact events (BusinessImpact and legacy business_impact)',
        type: 'event',
        label: 'BI Events',
        format: 'integer',
        apiName: 'businessImpactEvents',
        sortable: true,
        eventName: {
            value: ['BusinessImpact', 'business_impact'],
            operation: 'in'
        },
        calculation: 'count',
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleCostPerUserColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerUser',
        apiName: 'costPerUser',
        format: 'money',
        label: '$ / User',
        hint: 'Total cost of the Article viewed in the selected period divided by the number of Users who viewed the Article',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleCostPerPageViewColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerPageView',
        apiName: 'costPerPageView',
        format: 'money',
        label: '$ / Page View',
        hint: 'Total cost of the Article divided by the number of page views of the article in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleCostPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerArticle',
        apiName: 'costPerArticle',
        format: 'money',
        label: 'Cost',
        hint: 'Total cost of the Article',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleCostPerSocialReferralColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerSocialReferral',
        apiName: 'costPerSocialReferral',
        format: 'money',
        label: '$ / Social Ref',
        hint: 'Total cost of the Article divided by the number of Social Referrals to the Article in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleCostPerSearchReferralColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerSearchReferral',
        apiName: 'costPerSearchReferral',
        format: 'money',
        label: '$ / Search Ref',
        hint: 'Total cost of the Article divided by the number of Search Referrals to the Article in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleByVisitorsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.users',
        apiName: 'visitorsHistory',
        format: 'history',
        label: 'Users Trend',
        hint: 'History of the number of visitors to the Article in the selected period. Bars are matching your selection in the top chart.',
        sortable: false,
        pushToRight: true,
        groupByOperation: 'sumForEach'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleByViewsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.pageViews',
        apiName: 'pageViewsHistory',
        format: 'history',
        label: 'Page Views Trend',
        hint: 'History of the page views on the Article in the selected period. Bars are matching your selection in the top chart.',
        sortable: false,
        pushToRight: true,
        groupByOperation: 'sumForEach'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleBySocialReferralsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.socialReferrals',
        apiName: 'socialReferralsHistory',
        format: 'history',
        label: 'Social Referrals Trend',
        hint: 'History of the Social Referrals on the Article in the selected period. Bars are matching your selection in the top chart.',
        sortable: false,
        pushToRight: true,
        groupByOperation: 'sumForEach'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleBySearchReferralsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.searchReferrals',
        apiName: 'searchReferralsHistory',
        format: 'history',
        label: 'Search Referrals Trend',
        hint: 'History of the Organic Search referrals on the Article in the selected period. Bars are matching your selection in the top chart.',
        sortable: false,
        pushToRight: true,
        groupByOperation: 'sumForEach'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleUsersPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.usersPerArticle',
        apiName: 'usersPerArticle',
        format: 'decimal',
        label: 'Users',
        hint: 'Number of uniq users who visited the Article in the selected period divided by the number of Articles',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleAvgSessionDurationColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.avgSessionDuration',
        apiName: 'avgSessionDuration',
        format: 'time',
        label: 'Avg. Session Duration',
        hint: 'Average duration of a session where the Article was viewed in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleTotalSessionsDurationColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.totalSessionsDuration',
        apiName: 'totalSessionsDuration',
        format: 'time',
        label: 'Total Sessions Duration',
        hint: 'Total duration of all sessions where the Article was viewed in the selected period',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};
